import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-infra',
    templateUrl: './infra.component.html',
    styleUrls: ['./infra.component.scss']
})
export class InfraComponent implements OnInit {

    constructor(private backend: BackendApiService) { }
    ngOnInit(): void {
        this.backend.createVisitorLogs('Infrastructure-page');
    }
}
