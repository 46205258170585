import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-oil-gas',
    templateUrl: './oil-gas.component.html',
    styleUrls: ['./oil-gas.component.scss']
})
export class OilGasComponent implements OnInit {

    constructor(private backend: BackendApiService) { }

    ngOnInit(): void {
        this.backend.createVisitorLogs('Oil-Gas-page');
    }

}
