import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-manufacturing',
    templateUrl: './manufacturing.component.html',
    styleUrls: ['./manufacturing.component.scss']
})
export class ManufacturingComponent implements OnInit {

    constructor(private backend: BackendApiService) { }

    ngOnInit(): void {
        this.backend.createVisitorLogs('Manufacturing-page');
    }

}
