<head>
    <link rel="canonical" href="https://plusmax.com.my/#/realestate" />
</head>
<div class="page-banner bg-7">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container ">
                <div class="page-content">
                    <h2>Poolambadi</h2>
                    <ul>
                        <li><a routerLink="/csr">CSR</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="fon">
                        <h3>
                            <h2>Poolambadi CSR</h2>
                        </h3>
                        <div class="service-image">
                            <img src="assets/img/CSR/Donation.jpeg" alt="Plus max">
                        </div><br>
                        <p><i class='bx bxs-donate-heart'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>2022-2023 - Dato' S.
                            Prakadeesh Kumar has adopted the villages of Kadambur, Pudur, Arasadikadu and Melankudankadi,
                            under
                            the Perambalur district, Tamilnadu, India. The above villages are deficient in essential
                            commodities and basic amenities
                            such as water, electricity, road, drainage, etc., which are necessary for the decent livelihoods
                            of their residents.
                            Following discussions with the relevant Government representatives the total estimate for this
                            project would be over
                            200,000,000 (200 Million) Indian Rupees (~ RM 11,121,000) and Dato' kindly consented to sponsor
                            the entire amount.</strong></p>
                    <p><i class='bx bxs-donate-heart'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Dato' contributed an initial
                            amount of 9,000,000 (Nine Million) Indian Rupees (~ RM 500,100) as an advance to commence
                            this project.</strong></p>
                            <div class="service-image">
                                <img src="assets/img/Infra/poolambadiroad1.jpeg" alt="Plus max">
                            </div><br>
                    <p><i class='bx bxs-donate-heart'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Dato' contributed an amount of
                            6,023,000 (Six Million and twenty three thousand) Indian Rupees (~ RM 335,000) to
                            complete the second phase of this project.</strong></p>
                    <p><i class='bx bxs-donate-heart'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Dato' contributed an amount of
                            4,092,000 (Four Million and ninety two thousand) Indian Rupees (~ RM 228,000) to complete
                            the third phase of this project.
                        </strong></p>
                    <p><i class='bx bxs-donate-heart' style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>2022 - Dato S. Prakadeesh Kumar supported the construction and inaugurated the Draupathi Amman Temple in Poolambadi Village, Tamil Nadu. The objective of establishing is to assist individuals in developing a strong belief and discovering the inner purpose of their presence. A temple in India is synonymous with a place that relieves people of their difficulties and encourages them to strive for greater heights. It promotes a positive attitude and affirmations, which are quintessential for people to go about their daily lives.
                    </strong></p>
                    <div class="service-image">
                        <img src="assets/img/Info/Temple2.jpeg" alt="Plus max">
                    </div><br>
            </div>
        </div>

    </div>
    <div class="col-lg-4 col-md-12">
        <div class="service-sidebar">
            <h3 class="title">Corporate Social Responsibility</h3>
            <ul>
                <iframe width="400px" height="315px" src="https://www.youtube.com/embed/9v8waDoWBms?t=21">
                </iframe><br><br>
                <!-- <li><a><i class='bx bxs-donate-heart'></i>Caring for the Future</a></li> -->
            </ul>
        </div>
    </div>
</div>

