<head>
    <link rel="canonical" href="https://plusmax.com.my/#/services" />
</head>
<div class="page-banner group-bg-2 mt-5">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 class="text-center">Business Entities</h2>
                    <!-- <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="our-services-area ptb-100">
    <div data-aos="fade-up" data-aos-duration="3000" class="container">
        <div class="section-title">
            <h1>Business Entities</h1>
            <h3>A thousand miles journey starts with a single step. With our vigilant and sturdy steps we are able to
                prosper with 55+ Group of Companies in various Sectors.</h3>
        </div>
        <div class="row">
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services1.jpg" alt="Plus Max Duty-Free">
                    <div class="service-caption">
                        <h3>Retail Sales</h3>
                        <p>Plus Max has is one of Malaysia’s largest regional duty-free trading organization,
                        </p>
                        <a routerLink="/retail-sales" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services4.jpg" alt="Plus Max Oil and Gas">
                    <div class="service-caption">
                        <h3>Oil & Gas</h3>
                        <p>Plus Max entered the infrastructure and construction markets two decades ago..</p>
                        <a routerLink="/oil-gas" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/Import/ex3.jpeg" alt="Plus Max Import">
                    <div class="service-caption">
                        <h3>Import & Export</h3>
                        <p>Plus Max has established
                            itself as a prominent player in the wholesale import and export</p>
                        <a routerLink="/export-services" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/page-banner/Nautilus.jpeg" alt="Plus max hospitality">
                    <div class="service-caption">
                        <h3>Hospitality</h3>
                        <p>Inspirasi Langkawai
                            Sdn. Bhd works towards developing a prominent one-of-a-kind four-star luxury beach
                            resort,</p>
                        <a routerLink="/hospitality" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/Education/College3.jpg" alt="Plus Max education">
                    <div class="service-caption">
                        <h3>Education</h3>
                        <p>Plus Max has ventured into the education sector by becoming a prominent shareholder in
                            India’s...
                        </p>
                        <a routerLink="/services-details" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/IT4.jpg" alt="Plus Max Info Tech">
                    <div class="service-caption">
                        <h3>Information Technology</h3>
                        <p>Plus Max Info-Tech provides the complete IT support for the Group Companies.</p>
                        <a routerLink="/infotech" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/Finance/Agate-Finance.jpg" alt="Agate Finance">
                    <div class="service-caption">
                        <h3>Finance</h3>
                        <p>Agate Finance
                            Limited (related company of the Plus Max Group),
                        </p>
                        <a routerLink="/finance" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two justify-content-center">
                    <img src="assets/img/gallery/Prime.jpg" alt="Plus Max Real-Estate">
                    <div class="service-caption">
                        <h3>Real Estate</h3>
                        <p>Plus Max Real Estate has
                            successfully acquired a 208-acre parcel of land in Seberang Prai,
                        </p>
                        <a routerLink="/realestate" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two justify-content-center">
                    <img src="assets/img/Manufacturing/Picture 3.png" alt="Plus Max Real-Estate">
                    <div class="service-caption">
                        <h3>Manufacturing</h3>
                        <p>BJ Distillery Sdn Bhd (BJD), Malaysia, planning to setup an integrated bioethanol..
                        </p>
                        <a routerLink="/manufacturing" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
           
            <!-- <div data-aos="fade-up"
            data-aos-duration="3000" class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/services"><span aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/services">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/services"><span aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div> -->
        </div>
        <div class="row" style="place-content: space-evenly;">
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/Infra/SalemS4.jpeg" alt="image">
                    <div class="service-caption">
                        <h3>Infrastructure</h3>
                        <p> Plus Max
                            Infrastructure is actively developing affordable housing in Southern..
                        </p>
                        <a routerLink="/infra" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/goldone/GO.jpeg" alt="image">
                    <div class="service-caption">
                        <h3>Gold One</h3>
                        <p> Plus Max
                            Gold One Refinery Private Limited was ..
                        </p>
                        <a routerLink="/goldone" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
            <!-- <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two justify-content-center">
                    <img src="assets/img/Naturals/PMNATURALS.jpg" alt="Plus Max Real-Estate">
                    <div class="service-caption">
                        <h3>Plusmax Naturals</h3>
                        <p>Plus Max Naturals is a leading manufacturer of herbal extracts, providing exceptional 
                            solutions to a wide array of industries and business partners across the globe. 
                        </p>
                        <a routerLink="/naturals" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div> -->
            <!-- <div data-aos="fade-up" data-aos-duration="3000" class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/PLUS MAX FPO LOGO.png" alt="image">
                    <div class="service-caption">
                        <h3>Plus Max FPO</h3>
                        <p>
                            Plus Max Farmers Producer Organization (FPO)
                        </p>
                        <a routerLink="/fpo" class="default-btn-two">Explore More&nbsp;<i
                                class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
