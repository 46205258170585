import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
    selector: 'app-infotech',
    templateUrl: './infotech.component.html',
    styleUrls: ['./infotech.component.scss']
})
export class InfotechComponent implements OnInit {

    constructor(private backend: BackendApiService) { }

    ngOnInit(): void {
        this.backend.createVisitorLogs('Infotech-page');

    }

}
