import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';

@Component({
  selector: 'app-naturals',
  templateUrl: './naturals.component.html',
  styleUrls: ['./naturals.component.scss']
})
export class NaturalsComponent implements OnInit {

   constructor(private backend: BackendApiService) { }
  
      ngOnInit(): void {
          this.backend.createVisitorLogs('Infotech-page');
  
      }

}
