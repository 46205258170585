<head>
    <link rel="canonical" href="https://plusmax.com.my/#/hospitality" />
</head>
<div class="page-banner bg-9">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container ">
                <div class="page-content">
                    <h2 style="color: greenyellow;">Hospitality</h2>
                    <ul>
                        <li><a style="color: greenyellow;" routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12"
                style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: medium;">
                <div class="service-details-text">
                    <div class="fon">
                        <h3>
                            <h2>Hospitality</h2>
                        </h3>
                        <div class="service-image">
                            <img src="assets/img/page-banner/Nautilus.jpeg" alt="Plus Max hospitality">
                        </div>
                        <h2>The Nautilus Resort (Inspirasi Langkawi Sdn Bhd):</h2>
                        <p><i class='bx bx-restaurant'
                                style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Inspirasi Langkawai
                                Sdn. Bhd works towards developing a prominent one-of-a-kind four-star luxury beach
                                resort, The
                                Nautilus Resort, which will be situated in Pantai Cenang, Langkawi Island. This property
                                is managed by The Hilton Group
                                as part of their Curio Collection portfolio, which has been incorporated as a novel
                                resort that offers the ultimate
                                island getaway.</strong></p>
                        <p><i class='bx bx-restaurant'
                                style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Set to open in
                                November 2023.</strong>
                        </p>
                        <h2>Royal Agate Beach Resort</h2>
                        <div class="image">
                            <img src="assets/img/Hotel/Royal4.jpeg" alt="Plus Max hospitality">
                        </div>
                        <p><i class='bx bx-restaurant'
                                style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>
                                Royal Agate Beach Resort is a renowned Malaysian hospitality brand, which focuses on
                                extending warm hospitality, with
                                its captivating facilities and amenities. It is a boutique three-star hotel situated
                                near
                                the beaches of Pantai Cenang
                                in the Langkawi Islands. Our aesthetically-pleasing premise is a melange of comfort and
                                convenience, ensuring that our
                                guests have an unforgettable holiday and stay experience in the most exquisite setting.
                            </strong></p>
                        <div class="service-image">
                            <img src="assets/img/Hotel/Royal3.jpeg" alt="Plus Max hospitality">
                        </div>
                        <p><i class='bx bx-restaurant'
                                style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>With an iconic
                                roof-top restaurant that serves a variety of scrumptious cuisines with a perpetual
                                sunset view, it
                                creates memories that will be cherished and reminisced.</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <!-- <h3 class="title">Hospitality</h3> -->
                    <ul>
                        <li><a><i class='bx bx-restaurant'></i>Hospitality</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
