<head>
    <link rel="canonical" href="https://plusmax.com.my/#/naturals" />
</head> 
 <div class="page-banner bg-18">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Plusmax Naturals</h2>
                    <ul>
                        <li><a routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="plusmax-naturals">
  <div class="container">
    <h2>Plusmax Naturals</h2>
    <p>
      <i class="bx bxs-tree"></i>
      <strong>
        At Plus Max Naturals, we are committed to delivering premium, nature-inspired solutions, expertly crafted
        through advanced science and sustainable manufacturing practices at our state-of-the-art facility. Our plant
        combines cutting-edge technology with eco-friendly processes, ensuring the highest standards of quality. With a
        strong focus on innovation, dependability, and environmental stewardship, we empower businesses globally to
        create exceptional products that promote health, beauty, and overall well-being.
      </strong>
    </p>
    <p>
      <i class="bx bxs-tree"></i>
      <strong>
        As a trusted global partner, we focus on maintaining consistent quality, ensuring timely deliveries, and
        offering tailored solutions that meet the unique needs of our clients.
      </strong>
    </p>
    <p>
      <i class="bx bxs-tree"></i>
      <strong>
        At Plus Max Naturals, we don’t just supply products—we deliver excellence and foster lasting trust throughout
        every stage of our partnership.
      </strong>
    </p>

    <h3>Laboratory</h3>
    <p>
      <i class="bx bxs-tree"></i>
      <strong>
        At Plus Max Naturals, we take pride in our state-of-the-art laboratory, meticulously designed for quality
        assurance and research & development (R&D). Equipped with advanced analytical instruments and testing
        facilities, our lab ensures that every product meets the highest standards of purity, potency, and safety.
      </strong>
    </p>
    <p>
      <i class="bx bxs-tree"></i>
      <strong>
        Our quality control team monitors at every stage, from raw material selection to final product verification,
        ensuring compliance with global quality benchmarks. Beyond quality assurance, our laboratory is a dynamic hub
        for innovative research. Our team of skilled experts focuses on developing novel formulations, enhancing
        existing products, and advancing sustainable extraction technologies. By leveraging the latest scientific
        advancements, we aim to revolutionize the nutraceutical and cosmetic industries with groundbreaking solutions.
      </strong>
    </p>

    <h3>Our Products</h3>
    <table class="product-table">
      <thead>
        <tr>
          <th>Products</th>
          <th>Extracts</th>
          <th>Percentage</th>
          <th>Grade</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Marigold</td>
          <td>Free Lutein by HPLC</td>
          <td>1% - 75%</td>
          <td>Conventional | Ethanol</td>
        </tr>
        <tr>
          <td>Marigold</td>
          <td>Lutein Ester by UV</td>
          <td>1% - 90%</td>
          <td>Conventional | Ethanol</td>
        </tr>
        <tr>
          <td>Marigold</td>
          <td>Lutein Ester by HPLC</td>
          <td>1% - 80%</td>
          <td>Conventional | Ethanol</td>
        </tr>
        <tr>
          <td>Coleus</td>
          <td>Forskolhii by HPLC</td>
          <td>1% - 95%</td>
          <td>Conventional | Ethanol</td>
        </tr>
        <tr>
          <td>Turmeric</td>
          <td>Curcuminoids</td>
          <td>2% - 95%</td>
          <td>Conventional | Ethanol</td>
        </tr>
        <tr>
          <td>Black Pepper</td>
          <td>Essential Oil</td>
          <td></td>
          <td>Conventional | Ethanol</td>
        </tr>
        <tr>
          <td>Ashwagandha</td>
          <td>Withanolides by HPLC</td>
          <td>1.5% - 10%</td>
          <td>Conventional | Ethanol</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


  
  
    