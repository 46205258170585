<div id="line"></div>
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div data-aos="flip-left" class="logo text-center">
                        <h5 class="mt-1"><strong>PLUS MAX GROUP OF COMPANIES</strong></h5>
                        <img src="assets/img/plusmax-logo-new.png" width="70" height="70" alt="logo">
                    </div>
                    <ul class="footer-socials">
                        <li><a href="https://www.facebook.com/login/" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/Plus_Max_Groups" target="_blank"><i
                                    class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.linkedin.com/checkpoint/rm/sign-in-another-account" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>
                        <li><a href="https://www.instagram.com/accounts/login/" target="_blank"><i
                                    class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Quick Links</h3>
                    <ul class="footer-text">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/testimonials">Awards</a></li>
                        <li><a routerLink="/services">Business Entities</a></li>
                        <li><a routerLink="/blog">Who We Are</a></li>
                        <li><a routerLink="/csr">CSR</a></li>
                        <li><a routerLink="/careers">Careers</a></li>
                        <li><a routerLink="/gallery">Gallery</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Headquarters</h3>
                    <ul class="info-list">
                        <h4>Malaysia</h4>
                        <li style="color: white;"><i class='bx bxs-buildings fs-2'></i>Level 16,<br> Menara
                            Safuan,<br>
                            No.80, Jalan Ampang,<br>
                            50450 Kuala Lumpur,<br>
                            Malaysia.
                        </li>
                    </ul>
                    <ul class="info-list">
                        <h3>Regional Office</h3>
                        <h4>India</h4>
                        <li style="color: white;"><i class='bx bxs-buildings fs-2'></i>No. 86/18, Royal
                            Complex,<br>
                            Sankari Road,
                            Seetharampalayam<br>
                            Tiruchengode,<br> Namakkal-637209,<br>Tamil Nadu.
                        </li>
                    </ul>
                </div>
            </div>
            <div class=" col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3>Support</h3>
                    <ul class="footer-text info-list">
                        <li style="color:white;"><i class='bx bxs-envelope'></i> <a
                                href="mailto:mail@plusmax.com.my">mail@plusmax.com</a>
                        </li>
                        <li style="color: white;"><i class='bx bxs-phone'></i> <a
                                href="tel:+(60)-320266100">+(60)-320266100</a>
                        </li>
                        <li style="color: white;"><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<footer class="new_footer_area bg_color">
    <div class="footer-bottom">
        <div class="container">
            <p>Copyright &copy;&nbsp;{{footerDate}}. All Rights Reserved | Plus Max Group of Companies. Designed
                by&nbsp;<a href="https://plusmaxinfotech.com/" target="_blank">Plus Max Info Tech</a></p>
        </div>
    </div>
</footer>
<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>
