<head>
    <link rel="canonical" href="https://plusmax.com.my/#/home" />
</head>
<div class="hero-slider owl-carousel owl-theme mt-5 fon">
    <div class="hero-slider-item item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span>Welcome to</span>
                        <h1>PLUS MAX GROUP OF COMPANIES</h1>
                        <p>Our first business step commenced in the travel and retail industry in the year 1989 under
                            the banner AGATE DUTY FREE,
                            where we pioneered the concept of DUTY FREE PLAZA in Malaysia. In 2005 we rebranded our
                            corporate identity as Plus Max
                            and diversified our business sectors in Import & Export,
                            Infrastructure, Construction, Oil & Gas Services/Engineering, Information Technology,
                            Logistics, Warehousing, Hospitality & Property Management, Education and Manufacturing.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg7">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PLUS MAX - IMPORT & EXPORT<br> </h1>
                        <p>Plus Max has established itself as a credible and exemplary wholesale trader.It is important
                            to successfully operate a wide enough distribution network in the
                            business of wholesale
                            trading, export and import. Our network is supported with state-of-the-art private bonded
                            warehouses that are fully
                            equipped, together with a systematic logistics set-up. With a business portfolio of almost
                            30
                            years, we have established
                            strong business
                            relationships with key industry players, brand owners, principals and suppliers.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PLUS MAX INFO TECH<br> </h1>
                        <p>Plus Max Info-Tech provides the complete IT support for the Group Companies. We design and
                            implement software solution which enable our Entities to automate & transform the business
                            processes. We are expert in developing ERP Solutions, Websites, e-Commerce, Mobile APPs and
                            Cloud Architecture.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg8">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PLUS MAX - EDUCATION<br> </h1>
                        <p>Plus
                            Max has ventured into the education sector by becoming a prominent shareholder in India’s
                            first women engineering
                            college, Bharathiyar Institute of Engineering for Women.We
                            envisioned an educational institution that imparts quality
                            education and enhances technical competitiveness amongst faculty and students, gearing them
                            for the real world’s
                            challenges and inculcating a sense of dedication and discipline.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PLUS MAX FINANCE<br> </h1>
                        <p>We are please to announce that the Plus Max Group of Companies have diversified into the
                            financial sector with the
                            opening of our flagship branch at Poolambadi, Tamil Nadu under the brand AGATE FINANCE
                            LIMITED.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PLUS MAX - DUTY FREE<br> </h1>
                        <p>We are one of the largest local duty-free trading group in Malaysia, with strategic presence
                            at
                            all leading entry and
                            exit points in Peninsular Malaysia including airports, seaport, international ferry
                            terminal,
                            border towns and popular
                            tourist destinations. The business of Duty-free emanated from our growth as a travel
                            retailer
                            and post-rebranding we
                            have expanded internationally in India, Hong Kong, Dubai, Singapore and Rwanda.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg12">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PRIME CITY<br> </h1>
                        <p>Prime City Ventures Private Limited, one of the Plus Max Group of Companies opened its new
                            office in Chennai, Tamilnadu,
                            India on 09-Sep-2022.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg9">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PLUS MAX - REAL ESTATE<br> </h1>
                        <p>MALAYSIA - We have successfully secured a parcel land measuring 208 acres in Seberang Prai,
                            Penang.
                            INDIA - We have successfully secured an industrial plot measuring 400 acres in Arakonam
                            District, Tamilnadu, suitable for developing into various Industrial Sector.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg10">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PLUS MAX - OIL & GAS<br> </h1>
                        <p>Plus Max entered the infrastructure and construction markets two decades ago, with the
                            formation of Growth Avenue Sdn
                            Bhd (GASB). We have managed to broaden our prospects in the oil and gas industry.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-slider-item item-bg11">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>PLUS MAX - CONSTRUCTION<br> </h1>
                        <p>Plus Max Infrastructure executing two mega housing construction projects for Government of
                            Tamilnadu (year 2021), for
                            the underprivileged people.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="hero-slider-item item-bg13">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span></span>
                        <h1>Plus Max Naturals<br> </h1>
                        <p>Plus Max Naturals is an emerging leader in the herbal extraction industry, recognized 
                            for our advanced, sustainable extraction technology plant and laboratories that comply 
                            with global quality standards. As an innovative company, we specialize in producing 
                            premium herbal extracts for nutraceuticals, cosmetics, wellness, food, and other 
                            industries. Our commitment is to shape the future of herbal products, contributing to a 
                            healthier, more eco-conscious future. </p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
<div class="container fon">
    <div class="contact-area mb-85">
        <div class="contact-content">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-phone'></i>
                        <h4>Contact Number</h4>
                        <p><a href="tel:+(60)-320266100">+(60)-320266100</a></p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-location-plus'></i>
                        <h4>Our Locations</h4>
                        <p>Malaysia, India, Dubai,</p>
                        <p>Singapore, Hong Kong, Rwanda, Australia</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bx-show'></i>
                        <h4>Opening Hours</h4>
                        <p>9:00 AM - 6:00 PM</p>
                        <p>Monday - Saturday</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-envelope'></i>
                        <h4>Our Email</h4>
                        <p><a href="mailto: mail@plusmax.com"> mail@plusmax.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-area pb-100 fon">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="about-contant">
                    <div class="section-title" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">
                        <span data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">Advancing
                            Together </span>
                        <h2 data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">PLUS MAX GROUP OF
                            COMPANIES</h2>
                    </div>
                    <div class="about-text" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">
                        <p>
                            We, at Plus Max Group of Companies, have an integral purpose of amplifying growth by
                            ensuring excellence and innovation
                            in our line of business. Such is the crux of our organization and the mission we all work
                            effectively to accomplish. We
                            grow alongside all of our stakeholders to ensure the best for the present and future.
                        </p>
                        <p class="text-justify">The Plus Max Group, encompasses a three-decade business portfolio, is a
                            widely diversified conglomerate that has
                            advanced from being a travel retailer and commercial enterprise to an integrated player
                            across manufacturing,
                            construction, oil and gas, finance, wholesale distribution, hospitality, real-estate,
                            logistics, education, and digital
                            services.</p>
                        <a routerLink="/about" class="default-btn-one btn-bs">Explore More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/plusmax.jpg" alt="Plus Max">
                </div>
            </div>
            <div class="about-text" class="col-lg-3 col-md-12 fon">
                <div class="about-tabs">
                    <div class="tab-contant">
                        <h2 class="title">
                            Our Expertise</h2>
                        <div class="about-text" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="500">
                            <p class="text-justify">With a team of professional and knowledgeable employees, we provide
                                our zeal and grit towards seeking new methods and
                                avenues to ensure customer satisfaction. We focus on upholding the brand values for
                                higher service standards with
                                greater responsiveness and deliverance.</p>
                            <p class="text-justify">This steers us to add value and become a preferred partner for all
                                of our stakeholders, from suppliers to clients to our
                                employees.
                            </p>
                            <p>
                                Our organization is corroborated by a successful business portfolio that has allowed us
                                to strengthen relationships with
                                key industry leaders and execute complex, capital-intensive projects. Our company is
                                committed to providing value and
                                comfort to our clients' day-to-day operations.
                            </p>
                            <a routerLink="/about" class="default-btn-one btn-bs">Explore More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="services-area pt-100 pb-70 fon">
    <div class="container">
        <div class="section-title">
            <h2>Our Business Entities</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bxs-truck'></i>
                    <h3>Import & Export</h3>
                    <p>Plus Max has established itself as a prominent player in the wholesale import and export...</p>
                    <a routerLink="/export-services" class="default-btn-two">Explore More</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-store'></i>
                    <h3>Retail Sales</h3>
                    <p>Plus Max has is one of Malaysia’s largest regional duty-free trading organization..
                    </p>
                    <a routerLink="/retail-sales" class="default-btn-two">Explore More</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-laptop'></i>
                    <h3>Oil & Gas</h3>
                    <p>Plus Max entered the infrastructure and construction markets two decades ago, with the
                        formation...</p>
                    <a routerLink="/oil-gas" class="default-btn-two">Explore More</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-trending-up'></i>
                    <h3>Finance</h3>
                    <p>Agate Finance Limited (related company of the Plus Max Group), is a non-banking financial
                        company.
                    </p>
                    <a routerLink="/finance" class="default-btn-two">Explore More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="choose-area ptb-100 fon">
    <div class="shape-one"><img src="assets/img/shape/shape5.png" alt="Plus max"></div>
    <div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500" class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-world'></i>
                        <h3>Import & Export</h3>
                        <p>Plus Max has established itself as a prominent player in the wholesale import and export of
                            alcoholic beverages.</p>
                    </div>
                </div>
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bxs-paper-plane'></i>
                        <h3>Retail Sales</h3>
                        <p>Plus Max has is one of Malaysia’s largest regional duty-free trading organization, with its
                            strategic presence in nine
                            key entry and exit points in Peninsular Malaysia.</p>
                    </div>
                </div>
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-trending-up'></i>
                        <h3>Finance</h3>
                        <p>Agate Finance Limited (related company of the Plus Max Group), is a non-banking financial
                            company (NBFC)</p>
                    </div>
                </div>
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-gas-pump'></i>
                        <h3>Oil & Gas</h3>
                        <p>Plus Max entered the infrastructure and construction markets two decades ago, with the
                            formation of Growth Avenue Sdn
                            Bhd (GASB).</p>
                    </div>
                </div>
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-home'></i>
                        <h3>Real Estate</h3>
                        <p>Plus Max Real Estate has
                            successfully acquired a 208-acre parcel of land in Seberang Prai, Penang, situated on
                            Peninsular Malaysia's northwest coast.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="choose-text">
                    <div class="section-title ">
                        <h2>Our Core Value</h2>
                    </div>
                    <p data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">A stronger relationship
                        between partner brands effectively
                        pivots the process, allowing
                        for greater agility and consideration in identifying and serving emerging opportunities. Our
                        warehouses that are fully equipped with a systematic logistics setup.
                        Furthermore, our
                        30-year business portfolio has allowed us to strengthen relationships with key industry leaders
                        and collaborate on
                        profound projects with them.</p>
                    <p data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1500">With this outlook of
                        recognizing quality and innovation, we have expanded our horizons to include countries spanning
                        from Dubai to India, Singapore, Hong Kong, and Rwanda. Through this journey, we were able to
                        culminate a successful
                        business history, hands-on expertise, and the skills required to content stakeholders. These
                        enriching experiences made
                        it possible to diversify our business into the realms of logistics and warehousing, distribution
                        and wholesale, oil and
                        gas engineering, hotels and property management, education, and manufacturing.</p>
                    <p data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">Despite being a global
                        player, PlusMax always appreciates and embraces the heritage and customs of the regions in which
                        it has expanded. Our core objective is to satisfy all our customers and engage with their
                        requisites. Today, we are
                        firmly established as a leading player as a result of our business practices, expertise, and
                        determination to excel.</p>
                    <a routerLink="/contact" class="default-btn-one">Contact Us</a>
                    <div class="shape-image"><img src="assets/img/shape/shape2.png" alt="plusmax"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="testimonials-area ptb-100 fon">
    <div class="container">
        <div class="shape-two">
            <img src="assets/img/shape/shape6.png" class="shape-1" alt="plusmax">
            <img src="assets/img/shape/shape3.png" alt="plusmax">
        </div>
        <div class="section-title">
            <h2>GROUP MANAGING DIRECTOR</h2>
        </div>
        <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-card">
                <div class="client-img">
                    <!-- <img src="https://plusmax.com.my/assets/img/ceo.jpg" width="70" alt="image"> -->
                    <img src="assets/img/Info/dato_pic.jpg" width="70" alt="Dato Prakadeesh Kumar">
                    <h3>Dato' S. Prakadeesh Kumar</h3>
                    <span><b>GROUP MANAGING DIRECTOR</b></span>
                    <p>Plus Max Group of Companies</p>
                </div>
                <div data-aos="fade-down" class="feedback-text">
                    <h3><i class='bx bxs-quote-single-left'></i>Be the leading global player in every sector by adopting
                        best practices<br> that anticipate and satisfy people's desire and needs<i
                            class='bx bxs-quote-single-right'></i>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
