<head>
    <link rel="canonical" href="https://plusmax.com.my/#/realestate" />
</head>
<div class="page-banner bg-7">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container ">
                <div class="page-content">
                    <h2>Covid-19</h2>
                    <ul>
                        <li><a routerLink="/csr">CSR</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text">
                    <div class="fon">
                        <h3>
                            <h2>Covid-19</h2>
                        </h3>
                        <p><i><strong>During the covid pandemic, infections continue to occur around the world due to the impact of the corona virus. Precautionary measures were taken in Malaysia and curfew was imposed.
                            In this situation many Indians who were working for survival and students studying in
                            Malaysia are left helpless. To worsen the situation, flights between Malaysia and India have
                            been cancelled, leaving them stranded. Many such hapless people are requesting for help by
                            explaining the situation through social websites, which came to the notice of  Dato. S. Prakadeesh Kumar.</strong></i></p>
                        <p><i class='bx bxs-donate-heart' style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>
                            2021 - Dato S. Prakadeesh Kumar has Sponsored RM 120,000 to assist in the rescue of stranded Malaysians
                             from India to Kota Kinabalu, Sabah. For the expedition, a chartered air flight was booked,
                              take-offs were scheduled and arranged in accordance with the global circumstances
                              due to the spread of Covid-19.</strong>
                    </p>
                    <div class="service-image">
                        <img src="assets/img/Covidflight.png" alt="Plus max">
                    </div><br>
                    <p><i class='bx bxs-donate-heart' style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>2020
                            - Upon understanding the situation, Dato S. Prakadeesh Kumar has significantly contributed and attributed over RM 204,000 to rescue stranded Indian Nationals from Malaysia to Trichy, through chartered Redlands Aviation Air Flight. This was facilitated during the peak of the pandemic, when India imposed a Covid-19 lockdown.
                        </strong>
                    </p>
                    <div class="service-image">
                        <img src="assets/img/KLIA Airport.jpg" alt="Plus max">
                    </div><br>


                    <p><i class='bx bxs-donate-heart' style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>2020
                            - Also, Dato S. Prakadeesh Kumar aided more than RM 170,000, in rescuing stranded Indian nationals from Malaysia to Chennai, India, through Malindo Air Flight. This was facilitated and arranged during the peak of the pandemic, when India imposed a Covid-19 lockdown.
                        </strong></p>

                        <p><i> </i><strong>Speaking to one of the leading South Indian Magazine VIKATAN, Dato. mentioned the following:
                       </strong></p>

                        <p><i><strong>“The impact of the coronavirus is wreaking havoc globally. The situation in Malaysia is worser
                            than in India. In this context, there are no words to describe the plight and poor situation of the
                            Indians trapped here. Hence, I decided to embrace them immediately and have been providing food
                            and shelter to more than 600 affected Indians in Malaysia. I was waiting for the Bharath
                            Vande Bhrarath Scheme to function and then I arranged flights immedialtey for them at my own cost. Since only 179 passengers can be accommodated on a single flight, we are going to send the elderly
                            people and ladies first, considering their family background who would be worrying for their
                            return to India. The solo flight is scheduled to depart from Malaysia on Monday and also, we
                            have arranged to bring back people from Chennai to Malaysia on this return flight. I thank the
                            Almighty for giving me a chance to serve the poor stranded people.”</strong></i></p>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-12">
        <div class="service-sidebar">
            <h3 class="title">Article Link</h3>
            <ul>
                <li><a target="_blank" href="https://www.vikatan.com/news/tamilnadu/tamilnadu-based-businessman-helps-stranded-tamilis-in-malaysia"><i class='bx bxs-donate-heart'></i><strong>VIKATAN (South Indian Magazine)</strong></a></li>
            </ul>
        </div>
    </div>
</div>
