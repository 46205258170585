<head>
    <link rel="canonical" href="https://plusmax.com.my/#/services" />
</head>
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Import & Export</h2>
                    <ul>
                        <li><a routerLink="/services">Business Entities</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12"
                style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: medium;">
                <div class="service-details-text">
                    <div class="service-image">
                        <img src="assets/img/Import/ex3.jpeg" alt="Plus max import and export">
                    </div>
                    <h3>Import & Export</h3>
                    <p class="fon"><i class='bx bxs-ship'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong> Plus Max has established
                            itself as a prominent player in the wholesale import and export of alcoholic
                            beverages.</strong></p>
                    <div class="service-image">
                        <img src="assets/img/Import/ex1.jpeg" alt="Plus max import and export">
                    </div>
                    <p class="fon"><i class='bx bxs-plane-take-off'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>To provide the finest
                            services to our customers, we have established and strengthened business relationships with
                            key
                            industry players, brand owners, principals, and suppliers.</strong></p>
                    <div class="service-image">
                        <img src="assets/img/Import/export.jpeg" alt="Plus max import and export">
                    </div>
                    <p class="fon"><i class='bx bxs-ship'
                            style="color:cornflowerblue; font-size: 1.2em;"></i>&nbsp;<strong>Our distribution network
                            encompasses Malaysia, Singapore, Dubai, Hong Kong, India, and Rwanda. Thereby, our logistics
                            services ensure the efficient international flow of goods as well as the operation of global
                            and regional supply chains.</strong>
                    </p>
                    <div class="image">
                        <img src="assets/img/Import/export1.jpeg" alt="Plus max import and export">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Import & Export</h3>
                    <ul>
                        <li><a><i class='bx bxs-truck'></i> Road Freight</a></li>
                        <li><a><i class='bx bxs-ship'></i> Ocean Freight</a></li>
                        <li><a><i class='bx bxs-plane-take-off'></i> Air Freight</a></li>
                        <li><a><i class='bx bx-transfer'></i> B2B Exchange</a></li>
                        <li><a><i class='bx bx-home'></i> Home Shipping</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
