<body>
    <div class="container">
        <div class="box">
            <img src="assets/img/plusmax-logo-new.png" height="130" width="140" /><br>
            <div class="title">
                <span class="block"></span>
                <h1 style="font-family:serif">PLUS MAX<span></span></h1>
            </div>
            <div class="role">
                <div class="block"></div>
                <p><strong>GROUP OF COMPANIES</strong></p>
            </div>
        </div>
    </div>
</body>
